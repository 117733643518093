<template>
    <div>
        <modal v-show="isModalVisible" @close="closeModal">
            <span slot="header">Ta bort faktura</span>
            <span slot="body" class="text-sm"> Är du säker på att du vill ta bort den här fakturan?</span>
            <span slot="footer">
                <el-button size="mini" :loading="loading" type="danger" @click="removeInvoice()">Ta bort</el-button>
            </span>
        </modal>
        <modal v-show="deleteFailure" @close="closeDeleteModal"> <span slot="body"> Faktura kunde inte raderas när den är låst </span></modal>
    </div>
</template>
<script>
import modal from "../../../components/modal";

export default {
    props: ["isModalVisible", "deleteFailure", "loading"],

    components: {
        modal,
    },

    methods: {
        async removeInvoice() {
            this.$emit("deleteInvoice");
        },

        closeModal() {
            this.$store.commit("supplierInvoice/clearDeleteBtnLoading");
            this.$emit("update:isModalVisible", false);
        },

        closeDeleteModal() {
            this.$store.commit("supplierInvoice/clearDeleteBtnLoading");
            this.$emit("update:deleteFailure", false);
        },
    },
};
</script>
